import { Text } from '@grupoboticario/flora-react';

type ReplyerNameProps = {
  isAMessageFromSelectedContact: boolean;
  contactName: string;
};

export function ReplyerName({ isAMessageFromSelectedContact, contactName }: ReplyerNameProps) {
  if (!isAMessageFromSelectedContact) {
    return <Text css={{ color: '$nonInteractiveEmphasis', fontWeight: '$heavy' }}>Você</Text>;
  }

  return (
    <Text
      css={{
        textTransform: 'capitalize',
        color: '$actionableDefault',
        fontWeight: '$heavy',
      }}
    >
      {contactName.toLowerCase()}
    </Text>
  );
}
