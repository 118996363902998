import { Flex, FloraTypography } from '@grupoboticario/flora-react';
import { ContactCode } from './ContactCode.styled';
import { ContactInfoTitle } from './ContactInfoTitle.styled';
import { ContactInfoWrapper } from './ContactInfoWrapper.styled';
import { useContactStore } from '@/shared/state';

export function ContactInfoIr() {
  const { contact } = useContactStore();

  return (
    <ContactInfoWrapper direction="row" align="center" gapX="$6">
      <Flex direction="column">
        <ContactInfoTitle as="h3">Contato</ContactInfoTitle>
        <Flex direction="row">
          <FloraTypography
            as="span"
            fontSize="bodyLargeStandard"
            fontWeight="bold"
            css={{
              width: '120px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              color: '$actionableDefault',
            }}
            title={contact.name}
          >
            {contact.name}
          </FloraTypography>
        </Flex>
      </Flex>
      <Flex direction="column">
        <ContactInfoTitle as="h3">Código VD+</ContactInfoTitle>
        <ContactCode>{contact.vdId}</ContactCode>
      </Flex>
    </ContactInfoWrapper>
  );
}
