import { HTTPError } from 'ky';

const errors = {
  FORBIDDEN_CS_ACCESS: 'Revendedor não faz parte da sua estrutura.',
  RESELLER_NOT_FOUND: 'Revendedor não encontrado.',
  RESELLER_INVALID_PHONE: 'Revendedor não possui um telefone válido. Verifique o cadastro.',
  UNKNOWN: 'Ocorreu um erro ao processar sua requisição.',
};

/**
 * Extrai a mensagem de acordo com o tipo de erro recebido
 * @param err Objeto de erro
 * @returns Mensagem de erro
 */
export async function getErrorMessage(err: unknown): Promise<string> {
  if (!(err instanceof HTTPError)) {
    return errors.UNKNOWN;
  }

  const json = await err.response.json();
  const type = json.message;
  return errors[type] ?? errors.UNKNOWN;
}
