import { findTemplate } from '@/shared/services/api/instant-messenger/service';
import { useCallback, useEffect, useState } from 'react';

import { TemplateComponent } from '@/shared/services/api/instant-messenger/types';

const whatsAppTemplateResponseCache: { [key: string]: TemplateComponent[] } = {};

export function useFetchTemplate({ templateName }: { templateName: string }) {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [components, setComponents] = useState<TemplateComponent[] | null>(null);

  const fetchTemplate = useCallback(() => {
    if (whatsAppTemplateResponseCache[templateName]) {
      setComponents(whatsAppTemplateResponseCache[templateName]);
      setError(null);
      setLoading(false);
    } else {
      setLoading(true);
      findTemplate(templateName)
        .then(template => {
          whatsAppTemplateResponseCache[templateName] = template.components;
          setComponents(template.components);
          setError(null);
        })
        .catch(err => {
          setComponents([{ type: 'BODY', text: 'Não foi possível obter a mensagem do template.' }]);
          setError('Erro ao carregar o template: ' + (err.message || 'Erro desconhecido.'));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [templateName]);

  useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate]);

  return { loading, error, components };
}
