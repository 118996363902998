import { Text } from '../Text';

import {
  TemplateParamsByType,
  TemplateComponent,
  TemplateButtonsParams,
  TemplateHeaderParams,
} from '@/shared/services/api/instant-messenger/types/InstantMessengerType';
import { TemplateHeader } from './TemplateHeader';
import { TemplateButtons } from './TemplateButtons';

export function mergeParamsWithinTemplate({
  templatePlaceholder,
  params,
}: {
  templatePlaceholder: string;
  params: TemplateParamsByType['parameters'] | undefined;
}) {
  if (!params) return templatePlaceholder;

  return templatePlaceholder?.replace(/\{\{(\d+)\}\}/gm, (_match, paramPlaceholder: number) => {
    return `${params[paramPlaceholder - 1]['text']}`;
  });
}

const TEMPLATE_COMPONENT_ORDER = ['HEADER', 'BODY', 'FOOTER', 'BUTTONS'];

function sortedTemplateComponents(templateComponents: TemplateComponent[]) {
  return templateComponents.sort((a, b) => {
    return TEMPLATE_COMPONENT_ORDER.indexOf(a.type) - TEMPLATE_COMPONENT_ORDER.indexOf(b.type);
  });
}

export function TemplateComponentTypesSwitcher({
  templateComponents,
  templateParams,
}: {
  templateComponents: TemplateComponent[];
  templateParams: TemplateParamsByType[];
}) {
  return (
    <>
      {sortedTemplateComponents(templateComponents)?.map(component => {
        const paramsList = templateParams.find(variable => variable.type === component.type.toLowerCase())?.parameters;

        switch (component.type) {
          case 'HEADER':
            return (
              <TemplateHeader
                key={component.format}
                component={component}
                paramsList={paramsList as TemplateHeaderParams['parameters']}
              />
            );
          case 'BODY':
          case 'FOOTER':
            return (
              <Text
                key={component.text}
                text={mergeParamsWithinTemplate({ params: paramsList, templatePlaceholder: component.text })}
                isAMessageFromSelectedContact={false}
              />
            );
          case 'BUTTONS':
            return (
              <TemplateButtons component={component} paramsList={paramsList as TemplateButtonsParams['parameters']} />
            );
          default:
            return null;
        }
      })}
    </>
  );
}
