import { httpClientInstance } from '@/shared/services/api';

export type UploadMediaResponse = {
  id: string;
};

type CreateMediaUploadUrlResponse = {
  name: string;
  uploadUrl: string;
};

const mimeTypesToTransform = ['audio/x-m4a'];
const mimeTypesToTransformFrom = {
  'audio/x-m4a': 'audio/mp4',
};

export async function uploadMedia(file: File): Promise<UploadMediaResponse> {
  const { name, uploadUrl } = await createMediaUploadUrl(file.type);
  const isMimeTypeToTransform = mimeTypesToTransform.includes(file.type);
  const blob = await file.arrayBuffer();

  // Utilizado fetch nativo para não ter interferência da configuração global do http client
  // Esta requisição não deve ser realizada passando os headers de autorização da VDI
  const res = await fetch(uploadUrl, {
    method: 'PUT',
    headers: {
      'content-type': isMimeTypeToTransform ? mimeTypesToTransformFrom[file.type] : file.type,
      'content-length': file.size.toString(),
    },
    body: blob,
  });

  if (!res.ok) {
    throw new Error('Failed to upload media');
  }

  return { id: name };
}

async function createMediaUploadUrl(contentType: string): Promise<CreateMediaUploadUrlResponse> {
  const res = await httpClientInstance(`v1/medias`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      contentType,
    }),
  });

  return res.json<CreateMediaUploadUrlResponse>();
}
