import { httpClientInstance } from '@/shared/services/api';

type MethodType = 'GET' | 'POST' | 'DELETE' | 'PATCH' | 'PUT';

export interface FetcherOptions {
  timeout?: number;
  method?: MethodType;
  body?: FormData | ReadableStream;
  searchParams?: {
    [key: string | number]: string | number | boolean;
  };
  json?: unknown;
  headers?: {
    [key: string]: string;
  };
}

function fetcherFunction(url: string, method: MethodType, options?: FetcherOptions) {
  return httpClientInstance(url, { ...options, method });
}

const fetcherGet = async <T>(url: string, options?: FetcherOptions) => {
  return fetcherFunction(url, 'GET', options).json<T>();
};

const fetcherPost = async (url: string, options?: FetcherOptions) => {
  return fetcherFunction(url, 'POST', options);
};

const fetcherPatch = async (url: string, options?: FetcherOptions) => {
  return fetcherFunction(url, 'PATCH', options);
};

const fetcher = {
  get: fetcherGet,
  post: fetcherPost,
  patch: fetcherPatch,
};

export { fetcher };
