import { useEffect, useState } from 'react';
import { Box, Button, Card, Flex, Text } from '@grupoboticario/flora-react';
import { ChatBubbleOutlineIcon, ChevronRightIcon, LockIcon } from '@grupoboticario/flora-react-icons';
import { Menu, MenuItem, MenuDivider } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import { richTextIntoHTML } from '../../format/';

import { SendMessageData, getTemplates } from '@/shared/services/api/instant-messenger/service';
import { Template } from '@/shared/types/interfaces';

type TemplateFormMessageSenderProps = {
  onSendMessage(payload: SendMessageData): Promise<void>;
};

export function TemplateFormMessageSender({ onSendMessage }: TemplateFormMessageSenderProps) {
  const [templates, setTemplates] = useState<Array<Template>>([]);

  useEffect(() => {
    async function fetchTemplates() {
      const templates = await getTemplates();
      setTemplates(templates.data);
    }

    fetchTemplates();
  }, []);

  return (
    <Flex justify="center">
      <Menu
        arrow={true}
        align="center"
        direction="top"
        menuButton={
          <Card variant="active" css={{ margin: '1rem 0' }}>
            <Flex direction="row" justify="center" align="center">
              <LockIcon color="$9" />
              <Text css={{ margin: '0 1rem 0 .5rem', lineHeight: '1.2em' }}>
                A janela de conversa com este revendedor expirou.
                <br /> Use um dos modelos para iniciar uma conversa.
              </Text>
              <Button hierarchy="primary" styleSemantic="success" has="iconRight" icon={<ChevronRightIcon />}>
                Selecionar modelo
              </Button>
            </Flex>
          </Card>
        }
        transition
        onItemClick={e => {
          onSendMessage({
            provider: 'whatsapp',
            type: 'rawTemplate',
            rawTemplate: {
              name: e.value,
              components: [],
            },
          });
        }}
      >
        {templates.map((t, index) => {
          const items = [
            <MenuItem key={t.name} value={t.name}>
              <Box css={{ maxWidth: '500px' }}>
                <Flex direction="row" align="center">
                  <ChatBubbleOutlineIcon color="$actionableDefault" css={{ marginRight: '.5rem' }} />
                  <Text as="h6" css={{ fontSize: '$auxiliar', marginBottom: '3px' }}>
                    {t.title}
                  </Text>
                </Flex>
                {/*
                  No momento os templates suportados só possuem um elemento de BODY
                  Então é aceitável passar direto o campo texto do index "zero"
                */}
                <Text
                  size="bodyLargeStandardRegular"
                  dangerouslySetInnerHTML={{
                    __html: richTextIntoHTML(t.components[0].text),
                  }}
                ></Text>
              </Box>
            </MenuItem>,
          ];

          // Adicionamos o divisor caso não seja o último template
          if (index !== templates.length - 1) {
            items.push(<MenuDivider />);
          }

          return items;
        })}
      </Menu>
    </Flex>
  );
}
