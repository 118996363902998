import { useCallback, useEffect, useState } from 'react';
import { updateChat } from '@/shared/services/api/instant-messenger/service/updateChat';

export function useSetChatAsRead(contactId: string) {
  const [isPageVisible, setIsPageVisible] = useState(true);
  const [hasPendingMessagesToRead, setHasPendingMessagesToRead] = useState(false);

  const setChatAsRead = useCallback(() => {
    if (isPageVisible) {
      return updateChat(contactId, { status: 'read' });
    }

    setHasPendingMessagesToRead(true);
  }, [contactId, isPageVisible]);

  useEffect(() => {
    async function visibilityChangeHandler() {
      const isVisible = !document.hidden;
      setIsPageVisible(isVisible);
    }

    document.addEventListener('visibilitychange', visibilityChangeHandler);
    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
    };
  }, [contactId]);

  useEffect(() => {
    if (isPageVisible && hasPendingMessagesToRead) {
      setHasPendingMessagesToRead(false);
      setChatAsRead();
    }
  }, [contactId, isPageVisible]);

  return { setChatAsRead };
}
