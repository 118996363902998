import { FC } from 'react';
import { styled, Text, TextProps } from '@grupoboticario/flora-react';

export const ContactName = styled(Text, {
  color: '$nonInteractiveEmphasis',
  textTransform: 'capitalize',
  variants: {
    selected: {
      true: {
        color: '$actionableDefault',
      },
    },
    unread: {
      true: {
        fontWeight: '$bold',
      },
    },
  },
}) as FC<TextProps & { unread: boolean; selected: boolean }>;
