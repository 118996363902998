import { httpClientCAInstance } from '@/shared/services/api';

export interface GetResellerCreditsResponse {
  actualCyclePurchases: number;
  availableCredit: number;
  totalCredit: number;
  balanceCCR: number;
}

export async function getResellerCredits(id: string, signal: AbortSignal): Promise<GetResellerCreditsResponse> {
  const res = await httpClientCAInstance(`resellers/${id}/credits`, {
    method: 'GET',
    signal,
  });

  return res.json() as Promise<GetResellerCreditsResponse>;
}
