import { getResellerCredits } from '@/shared/services/api/ca/services';
import { useQuery } from '@tanstack/react-query';

export const useResellerCredits = ({ vdId }: { vdId: string }) => {
  return useQuery({
    queryKey: ['resellerCredits', vdId],
    queryFn: ({ signal }) => getResellerCredits(vdId, signal),
    enabled: !!vdId,
    initialData: {
      actualCyclePurchases: 0,
      availableCredit: 0,
      totalCredit: 0,
      balanceCCR: 0,
    },
  });
};
