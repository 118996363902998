import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Box, Flex, Button, Spinner, keyframes } from '@grupoboticario/flora-react';
import { PlusCircleIcon } from '@grupoboticario/flora-react-icons';
import useClickAway from 'react-use/lib/useClickAway';

import { SendMessageData } from '@/shared/services/api/instant-messenger/service';

import { AudioUploaderButton } from './components/AudioUploaderButton';
import { DocumentUploaderButton } from './components/DocumentUploaderButton';
import { ImageUploaderButton } from './components/ImageUploaderButton';
import { VideoUploaderButton } from './components/VideoUploaderButton';

type MediaUploaderMenuProps = {
  onSendMessage(payload: SendMessageData): Promise<void>;
};

const progressGrow = keyframes({
  '0%': { width: '0', opacity: 0 },
  '20%': { opacity: 1 },
  '100%': { width: '99%' },
});

const progressRoll = keyframes({
  '0%': { backgroundPosition: '1rem 0' },
  '100%': { backgroundPosition: '0 0' },
});

function MediaUploadProgress({ isUploadingMedia }) {
  const mediaUploadProgressRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mediaUploadProgressRef.current) {
      mediaUploadProgressRef.current = document.querySelector('#media-upload-progress')!;
    }
  }, [isUploadingMedia]);

  if (!mediaUploadProgressRef.current) {
    return null;
  }

  function ProgressBar() {
    if (!isUploadingMedia) {
      return null;
    }

    return (
      <Flex
        css={{
          width: '99%',
          height: '8px',
          animation: `5s cubic-bezier(0,.62,.62,1) 0s ${progressGrow}, 0.8s linear 0s infinite ${progressRoll}`,
          backgroundImage:
            'linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent)',
          backgroundSize: '1rem 1rem',
          backgroundColor: '$whatsapp',
          opacity: 1,
        }}
      >
        {' '}
      </Flex>
    );
  }

  return createPortal(<ProgressBar />, mediaUploadProgressRef.current);
}

export function MediaUploaderMenu({ onSendMessage }: MediaUploaderMenuProps) {
  const mediaUploaderMenuRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isUploadingMedia, setIsUploadingMedia] = useState(false);
  useClickAway(mediaUploaderMenuRef, () => {
    if (!isUploadingMedia) {
      setIsOpen(false);
    }
  });

  useEffect(
    function blockResellerSelectionOnMediaUploading() {
      const resellerList = document.querySelector('#reseller-list') as HTMLDivElement;

      if (resellerList) {
        resellerList.style.pointerEvents = isUploadingMedia ? 'none' : '';
      }
      return () => {
        resellerList.style.pointerEvents = '';
      };
    },
    [isUploadingMedia],
  );

  return (
    <Box ref={mediaUploaderMenuRef} css={{ position: 'relative' }}>
      <MediaUploadProgress isUploadingMedia={isUploadingMedia} />
      <Button
        hierarchy="tertiary"
        has="iconOnly"
        css={{
          position: 'relative',
          zIndex: 20,
          color: `${isOpen ? '$nonInteractiveAltAuxiliar' : '$backgroundPrimary'}`,
          transform: `${isOpen ? 'rotate(45deg)' : 'rotate(0)'}`,
          transition: 'color 0s, transform 0.2s',
          '& svg': {
            width: '24px',
            height: '24px',
          },
          padding: '11px',
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
        }}
        aria-label="Media menu"
        onClick={() => setIsOpen(!isOpen)}
        icon={isUploadingMedia ? <Spinner speed="400ms" size="sm" /> : <PlusCircleIcon />}
      />

      <Flex
        direction="column"
        align="center"
        justify="space-evenly"
        css={{
          position: 'absolute',
          bottom: '0',
          left: 'calc(50% - 24px)',
          backgroundColor: '$backgroundPrimary',
          zIndex: 10,
          borderRadius: `${isOpen ? '4px 4px 24px 24px' : '4px 4px 0px 0px'}`,
          width: '100%',
          minHeight: '180px',
          paddingBottom: `${isOpen ? '50px' : '0'}`,
          transition: `${isOpen ? 'opacity 200ms ease-out, transform 150ms ease-out' : 'all 0s'}`,
          transform: `${isOpen ? 'scale(1, 1)' : 'scale(1, 0)'}`,
          transformOrigin: 'bottom',
          opacity: `${isOpen ? '1' : '0'}`,
          boxShadow: '$upMid',
          '& > label': {
            pointerEvents: `${isUploadingMedia ? 'none' : 'auto'}`,
          },
          '& svg': {
            color: `${isUploadingMedia ? '#ccc' : 'currentColor'} !important`,
          },
        }}
      >
        <ImageUploaderButton
          onSendMessage={onSendMessage}
          setIsUploadingMedia={setIsUploadingMedia}
          setMediaUploaderMenuVisibility={setIsOpen}
          isUploadingMedia={isUploadingMedia}
        />
        <AudioUploaderButton onSendMessage={onSendMessage} setIsUploadingMedia={setIsUploadingMedia} />
        <DocumentUploaderButton onSendMessage={onSendMessage} setIsUploadingMedia={setIsUploadingMedia} />
        <VideoUploaderButton onSendMessage={onSendMessage} setIsUploadingMedia={setIsUploadingMedia} />
      </Flex>
    </Box>
  );
}
