import { useRef } from 'react';
import { useFetchContactWithMutation } from '@/shared/hooks/useFetchContact';

import { FloraInput, FloraButton } from '@grupoboticario/flora-react';
import { ArrowRightIcon } from '@grupoboticario/flora-react-icons';
import { ContactSearchForm } from './ContactSearchForm.styled';

export const ContactSearch = () => {
  const inputRef = useRef<HTMLInputElement>();
  const { mutate: fetchContact, isPending: isLoadingContact } = useFetchContactWithMutation();

  function leaveOnlyNumbers(value: string) {
    return value.replace(/\D/g, '');
  }

  function searchContact() {
    const contactVdId = inputRef.current?.value;
    if (!contactVdId) {
      return;
    }
    fetchContact({ vdId: leaveOnlyNumbers(contactVdId) });
  }

  return (
    <ContactSearchForm>
      <FloraInput
        id="searchResellerField"
        role="textbox"
        label="Buscar por código revendedor (VD+)"
        ref={inputRef}
        rightIcon={
          <FloraButton
            isLoading={isLoadingContact}
            hierarchy="tertiary"
            size="small"
            has="iconOnly"
            type="submit"
            aria-label={'Criar novo chat'}
            role="button"
            icon={<ArrowRightIcon />}
            onClick={event => {
              event.preventDefault();
              searchContact();
            }}
          />
        }
        onKeyUp={event => {
          if (event.key === 'Enter') {
            searchContact();
          }
        }}
        onInput={event => {
          inputRef.current.value = leaveOnlyNumbers(event.currentTarget.value);
        }}
        autoComplete="off"
        maxLength={10}
        type="number"
        css={{
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '&[type="number"]': {
            '-moz-appearance': 'textfield',
          },
        }}
      />
    </ContactSearchForm>
  );
};
