import { useEffect, useRef, useState } from 'react';
import MicRecorder from 'mic-recorder-to-mp3';
import { styled, keyframes } from '@grupoboticario/flora';
import { FloraButton, Flex, Box } from '@grupoboticario/flora-react';
import {
  CrossCircleIcon,
  PaperAirplaneIcon,
  PersonHeadsetIcon,
  StopIcon,
  TrashIcon,
} from '@grupoboticario/flora-react-icons';

import { uploadMedia } from '@/shared/services/api/instant-messenger/service/uploadMedia';
import { events } from '@/shared/services/events';
import { SendMessageData } from '@/shared/services/api/instant-messenger/service';
import { sendErrorToNewRelic } from '@/shared/functions/newRelic';
import { useAccountStore } from '@/shared/state';

type AudioRecorderProps = {
  sendMessageWithReply: (payload: SendMessageData) => Promise<void>;
  setIsAudioRecorderActive: (value: boolean) => void;
};

const quiet = keyframes({
  '25%': {
    transform: 'scaleY(.6)',
  },
  '50%': {
    transform: 'scaleY(.4)',
  },
  '75%': {
    transform: 'scaleY(.8)',
  },
});
const normal = keyframes({
  '25%': {
    transform: 'scaleY(1)',
  },
  '50%': {
    transform: 'scaleY(.4)',
  },
  '75%': {
    transform: 'scaleY(.6)',
  },
});
const loud = keyframes({
  '25%': {
    transform: 'scaleY(1)',
  },
  '50%': {
    transform: 'scaleY(.4)',
  },
  '75%': {
    transform: 'scaleY(1.2)',
  },
});

const AudioRecorderWrapper = styled(Flex, {
  backgroundColor: '$backgroundPrimary',
  height: '40px',
  minWidth: '40%',
  borderRadius: '$medium',
  '& audio::-webkit-media-controls-enclosure': {
    background: 'white',
  },
  '& audio::-webkit-media-controls-volume-control-container': {
    display: 'none',
  },
  '& .boxContainer': {
    display: 'flex',
    'justify-content': 'space-between',
    height: '30px',
    width: 'calc((4px + 2px) * 10)',
    margin: '0 1rem',
  },
  '& .box': {
    transform: 'scaleY(.4)',
    height: '100%',
    width: '4px',
    backgroundColor: '$statusInfoNonInteractiveEmphasis',
    'animation-duration': '1.2s',
    'animation-timing-function': 'ease-in-out',
    'animation-iteration-count': 'infinite',
    'border-radius': '8px',
  },
  '& .box1': {
    'animation-name': `${quiet}`,
  },
  '& .box2': {
    'animation-name': `${normal}`,
  },
  '& .box3': {
    'animation-name': `${quiet}`,
  },
  '& .box4': {
    'animation-name': `${loud}`,
  },
  '& .box5': {
    'animation-name': `${quiet}`,
  },
});

function SoundWave() {
  return (
    <div className="boxContainer">
      <div className="box box1"></div>
      <div className="box box2"></div>
      <div className="box box3"></div>
      <div className="box box4"></div>
      <div className="box box5"></div>
      <div className="box box1"></div>
      <div className="box box2"></div>
      <div className="box box3"></div>
      <div className="box box4"></div>
      <div className="box box5"></div>
    </div>
  );
}

const mediaRecorder = new MicRecorder({
  bitRate: 128,
});

function Counter({ status }: { status: 'idle' | 'recording' | 'stopped' }) {
  const [second, setSecond] = useState(0);

  function formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  useEffect(() => {
    let interval = null;
    if (status === 'recording') {
      interval = setInterval(() => {
        setSecond(second => second + 1);
      }, 1000);
    }

    if (status === 'idle' && second > 0) {
      setSecond(0);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [status]);

  return (
    <span
      style={{
        fontFamily:
          'Space Grotesk, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
        fontVariantNumeric: 'tabular-nums',
      }}
    >
      {formatTime(second)}
    </span>
  );
}

export function AudioRecorder({ sendMessageWithReply, setIsAudioRecorderActive }: AudioRecorderProps) {
  const refAudio = useRef<HTMLAudioElement>(null);
  const [audioResult, setAudioResult] = useState<string>('');
  const [audioFile, setAudioFile] = useState<File | undefined>(undefined);
  const [recordingStatus, setRecordingStatus] = useState<'idle' | 'recording' | 'stopped'>('idle');
  const [audioMutationStatus, setAudioMutationStatus] = useState<'idle' | 'sending' | 'error'>('idle');
  const { account } = useAccountStore();

  function startRecording() {
    setAudioResult('');
    setAudioFile(undefined);
    mediaRecorder
      .start()
      .then(() => {
        setRecordingStatus('recording');
      })
      .catch((err: Error) => sendErrorToNewRelic(err));
  }

  function stopRecording() {
    setRecordingStatus('stopped');
    mediaRecorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const audioData = new Blob(buffer, { type: blob.type });
        const file = new File(buffer, 'record.mp3', {
          type: 'audio/mpeg',
          lastModified: Date.now(),
        });
        setAudioResult(window.URL.createObjectURL(audioData));
        setAudioFile(file);
      });
  }

  function resetRecording() {
    mediaRecorder.stop();
    setRecordingStatus('idle');
    setAudioResult('');
    setAudioFile(undefined);
  }

  function closeAudioRecorder() {
    mediaRecorder.stop();
    setRecordingStatus('idle');
    setAudioResult('');
    setAudioFile(undefined);
    setIsAudioRecorderActive(false);
  }

  async function sendAudio() {
    try {
      setAudioMutationStatus('sending');
      const { id } = await uploadMedia(account.id, audioFile);
      await sendMessageWithReply({
        provider: 'whatsapp',
        type: 'audio',
        audio: {
          id,
        },
      });
      events.emit('NOTIFICATION', {
        description: `Áudio ${audioFile.name} enviado com sucesso`,
        kind: 'success',
      });
      closeAudioRecorder();
    } catch (error) {
      events.error({ description: 'Erro ao enviar mensagem do tipo gravação, tente novamente.' });
    } finally {
      setAudioMutationStatus('idle');
    }
  }

  return (
    <>
      <Box css={{ flex: '0 0 auto', marginLeft: 'auto' }}>
        <FloraButton
          hierarchy="tertiary"
          has="iconOnly"
          aria-label="asd"
          onClick={() => closeAudioRecorder()}
          css={{
            color: 'white',
            '&:hover': { backgroundColor: 'transparent' },
            '& svg': { width: '24px', height: '24px' },
            padding: '11px',
          }}
          icon={<CrossCircleIcon />}
        />
      </Box>
      <AudioRecorderWrapper align="center" justify="center" css={{ height: '48px' }}>
        {recordingStatus === 'idle' && (
          <FloraButton
            hierarchy="tertiary"
            has="iconOnly"
            aria-label="asd"
            onClick={() => startRecording()}
            css={{
              color: '$actionableDefault',
              '&:hover': { backgroundColor: 'transparent' },
              '& svg': { width: '24px', height: '24px' },
              padding: '11px',
            }}
            icon={<PersonHeadsetIcon />}
          />
        )}
        {recordingStatus === 'recording' && (
          <FloraButton
            hierarchy="tertiary"
            has="iconOnly"
            icon={<StopIcon />}
            aria-label="asd"
            onClick={() => stopRecording()}
            css={{
              '&:hover': { backgroundColor: 'transparent' },
              '& svg': { width: '24px', height: '24px' },
              padding: '11px',
            }}
          />
        )}

        {recordingStatus === 'recording' && <SoundWave />}
        {recordingStatus === 'recording' && <Counter status={recordingStatus} />}
        {recordingStatus === 'stopped' && audioFile && (
          <FloraButton
            hierarchy="tertiary"
            has="iconOnly"
            aria-label="asd"
            onClick={() => resetRecording()}
            css={{
              color: '$statusErrorActionableDefault',
              '&:hover': { backgroundColor: 'transparent' },
              '& svg': { width: '24px', height: '24px' },
              padding: '11px',
            }}
            icon={<TrashIcon />}
          />
        )}
        {recordingStatus === 'stopped' && (
          <audio
            src={audioResult}
            controls
            ref={refAudio}
            style={{ height: '32px' }}
            controlsList="nodownload nofullscreen noremoteplayback noplaybackrate nomuter novolume"
          />
        )}
      </AudioRecorderWrapper>
      <Box css={{ flex: '0 0 auto' }}>
        <FloraButton
          hierarchy="tertiary"
          styleSemantic="neutral"
          surfaceColor="dark"
          has="iconOnly"
          icon={<PaperAirplaneIcon />}
          aria-label="asd"
          onClick={() => sendAudio()}
          disabled={
            recordingStatus === 'recording' || audioMutationStatus === 'sending' || (!audioFile && !audioResult)
          }
          css={{
            color: 'white',
            '&:hover': { backgroundColor: 'transparent' },
            '& svg': { width: '24px', height: '24px' },
            padding: '11px',
          }}
        />
      </Box>
    </>
  );
}
