import { fetcher } from '../../fetcher';

type SendReactionRequest = {
  reaction: string;
};

export async function sendReaction(
  accountId: string,
  contactId: string,
  messageId: string,
  body: SendReactionRequest,
): Promise<void> {
  await fetcher.patch(`v1/accounts/${accountId}/messages/${contactId}/${messageId}`, {
    json: body,
  });
}
