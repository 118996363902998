import { fetcher } from '@/shared/services/api/fetcher';
import { GetTemplatesResponse, Template } from '@/shared/types/interfaces';

export async function getTemplates(accountId: string): Promise<GetTemplatesResponse> {
  const uri = `v1/accounts/${accountId}/templates`;
  return fetcher.get<GetTemplatesResponse>(uri, {});
}

export async function findTemplate(accountId: string, name: string): Promise<Template> {
  const uri = `v1/accounts/${accountId}/templates/${name}`;
  return fetcher.get<Template>(uri, {});
}
