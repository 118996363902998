import { styled, Flex, FlexProps } from '@grupoboticario/flora-react';
import { FC } from 'react';

export const ContactItem = styled(Flex, {
  position: 'relative',
  lineHeight: '$6',
  cursor: 'pointer',
  padding: '$3 $4 $3 $4',

  '&:not(:first-of-type)': {
    borderTop: '1px solid #d1d7db',
  },

  '&:hover': {
    backgroundColor: '#f5f6f6',
    borderTopColor: 'transparent',
  },

  '&:first-child:last-child': {
    borderBottom: '1px solid #d1d7db',
  },

  variants: {
    selected: {
      true: {
        backgroundColor: '#f0f2f5',
        borderTopColor: 'transparent',
        boxShadow: 'inset -2px 0 0 var(--flora--colors-actionableDefault)',
      },
    },
    status: {
      read: {},
      unread: {
        '&::after': {
          content: '',
          width: '8px',
          height: '8px',
          position: 'absolute',
          bottom: '18px',
          right: '16px',
          backgroundColor: 'var(--flora--colors-whatsapp)',
          display: 'block',
          borderRadius: '50%',
        },
      },
    },
  },
}) as FC<FlexProps & { selected: boolean; status: 'read' | 'unread' }>;
