import { FloraTypography } from '@grupoboticario/flora-react';

type ReplyerNameProps = {
  isAMessageFromSelectedContact: boolean;
  contactName: string;
};

export function ReplyerName({ isAMessageFromSelectedContact, contactName }: ReplyerNameProps) {
  if (!isAMessageFromSelectedContact) {
    return (
      <FloraTypography as="span" fontSize="bodySmallShort" fontWeight="bold" css={{ color: '$nonInteractiveEmphasis' }}>
        Você
      </FloraTypography>
    );
  }

  return (
    <FloraTypography
      as="span"
      fontSize="bodySmallShort"
      fontWeight="bold"
      css={{
        textTransform: 'capitalize',
        color: '$actionableDefault',
      }}
    >
      {contactName.toLowerCase()}
    </FloraTypography>
  );
}
