import { ChangeEvent, useState } from 'react';
import { AudioIcon } from '@grupoboticario/flora-react-icons';

import { SendMessageData } from '@/shared/services/api/instant-messenger/service';
import { uploadMedia } from '@/shared/services/api/instant-messenger/service/uploadMedia';
import { events } from '@/shared/services/events';
import { createFileValidationSchema, validateFile } from '../validations';
import MediaUploaderButton from './MediaUploaderButton';

type AudioUploaderButtonProps = {
  onSendMessage: (payload: SendMessageData) => Promise<void>;
  setIsUploadingMedia: (isUploading: boolean) => void;
};

const AUDIO_MAX_FILE_SIZE = 16;
const ALLOWED_AUDIO_MIME_TYPE = [
  /*
   * Esses são os tipos de arquivos de áudio que o WhatsApp Cloud API aceita
   * https://developers.facebook.com/docs/whatsapp/cloud-api/reference/media#supported-media-types
   */
  'audio/aac',
  'audio/mp4',
  'audio/mpeg',
  'audio/amr',
  'audio/ogg',
  'audio/opus',
  /*
   * Esses são os tipos de arquivos de áudio não oficiais
   * que testei e funcionaram no WhatsApp Cloud API
   */
  'audio/mp3',
  'audio/wav',
  'audio/x-m4a',
];

export function AudioUploaderButton({ onSendMessage, setIsUploadingMedia }: AudioUploaderButtonProps) {
  const [renderCount, setRenderCount] = useState(0);

  async function uploadAudio(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target?.files?.length) {
      return;
    }

    const file = e.target.files[0];

    const validationResult = validateFile(
      file,
      createFileValidationSchema({
        allowedMimeTypeList: ALLOWED_AUDIO_MIME_TYPE,
        maxFileSizeInMB: AUDIO_MAX_FILE_SIZE,
      }),
    );
    if (!validationResult.isValid) {
      events.error({ description: validationResult.error });
      return;
    }

    try {
      setIsUploadingMedia(true);
      const { id } = await uploadMedia(file);
      await onSendMessage({
        provider: 'whatsapp',
        type: 'audio',
        audio: {
          id,
        },
      });
      events.emit('NOTIFICATION', {
        description: `Áudio ${file.name} enviado com sucesso`,
        kind: 'success',
      });
    } catch (error) {
      return events.error({ description: 'Erro ao enviar mensagem do tipo áudio, tente novamente.' });
    } finally {
      setIsUploadingMedia(false);
      setRenderCount(renderCount => renderCount + 1);
    }
  }

  return (
    <>
      <MediaUploaderButton
        IconComponent={<AudioIcon />}
        htmlFor="audio-input-uploader"
        ariaLabel="Clique para selecionar um arquivo de áudio para enviar"
      />

      <input
        key={renderCount}
        hidden
        type="file"
        id="audio-input-uploader"
        accept={ALLOWED_AUDIO_MIME_TYPE.join(',')}
        onChange={uploadAudio}
      />
    </>
  );
}
