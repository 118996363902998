import { Text } from '@grupoboticario/flora-react';
import { format, formatRelative, subDays, isToday, isYesterday, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const TODAY = 0;
interface MessageDateTimeProps {
  createdAt: string;
}
const formatRelativeDate = (dateTime: string): string => {
  return formatRelative(subDays(parseISO(dateTime), TODAY), new Date(), {
    locale: ptBR,
  });
};

const formatDate = (dateTime: string): string => {
  return format(parseISO(dateTime), "dd/MM/yyyy 'às' HH:mm");
};

export const MessageDateTime = ({ createdAt }: MessageDateTimeProps): JSX.Element => {
  const checkIsToday = isToday(parseISO(createdAt));
  const checkIsYesterday = isYesterday(parseISO(createdAt));
  const date = checkIsToday || checkIsYesterday ? formatRelativeDate(createdAt) : formatDate(createdAt);

  return <Text size="auxiliarMini">{date}</Text>;
};
