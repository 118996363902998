import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, Flex, Grid } from '@grupoboticario/flora-react';

import { MessageSwitcher } from '../MessageSwitcher';
import { useSetChatAsRead } from '../../hooks';
import BackImage from '../../assets/images/bg-im.jpg';

import { getMessages } from '@/shared/services/api/instant-messenger/service';
import {
  Message,
  MessageReactionReceived,
  MessageReceived,
  MessageSent,
  MessageStatusReceived,
  Reaction,
} from '@/shared/services/api/instant-messenger/types';
import { events } from '@/shared/services/events';
import { useFixedScroll } from '@/shared/hooks';
import { Loading } from '@/shared/components';
import { sendReaction } from '@/shared/services/api/instant-messenger/service/sendReaction';
import { useAccountStore, useChatStore, useContactStore } from '@/shared/state';
import { sendErrorToNewRelic } from '@/shared/functions/newRelic';

type MessagesContainerProps = {
  isChatSessionOpen: boolean;
  setIsChatSessionOpen: (isChatSessionOpen: boolean) => void;
};

export function MessagesContainer({ isChatSessionOpen, setIsChatSessionOpen }: MessagesContainerProps) {
  const { account } = useAccountStore();
  const { contact } = useContactStore();
  const { chat } = useChatStore();

  const [messages, setMessages] = useState<Array<Message>>(undefined);
  const [next, setNext] = useState<string | undefined>();
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { setChatAsRead } = useSetChatAsRead(chat?.id);

  async function fetchData(reset?: boolean) {
    try {
      const res = await getMessages(account.id, { contactId: contact.id, next });
      const reverseMsgs = res.data.reverse();
      if (reset) {
        setMessages(reverseMsgs);
      } else {
        setMessages(msgs => [...reverseMsgs, ...msgs]);
      }
      setNext(res.meta?.next);
    } catch (error) {
      setMessages([]);
      setIsChatSessionOpen(false);
      sendErrorToNewRelic(error);

      if (error.response?.status === 403) {
        events.error({
          description: `Você não está mais como responsável pelo revendedor ${contact.name} -  ${contact.vdId}`,
        });
      }
    }
  }

  useEffect(() => {
    async function fetchAndSetChatAsRead() {
      await fetchData(true);
      await setChatAsRead();
    }

    fetchAndSetChatAsRead();
  }, [account.id, contact.id]);

  useFixedScroll({ el: messagesContainerRef, hasMore: next, fetchData });

  useEffect(() => {
    async function messageReceivedHander(event: MessageReceived) {
      if (event.contact.id !== contact.id || event.account.id !== account.id) {
        return;
      }

      setMessages(msgs => [...msgs, event.message]);
      await setChatAsRead();
    }

    function messageReactionReceivedHander(event: MessageReactionReceived) {
      if (event.contact.id !== contact.id || event.account.id !== account.id) {
        return;
      }

      setMessages(msgs =>
        msgs.map(m => (m.id === event.message.id ? { ...m, reactions: mergeReactions(m, event.message.reaction) } : m)),
      );
    }

    function messageStatusReceivedHander(event: MessageStatusReceived) {
      if (event.account.id !== account.id || event.contact.id !== contact.id) {
        return;
      }

      setMessages(msgs =>
        msgs.map(m =>
          isStatusUpdate(m, event) ? { ...m, status: event.message.status, updatedAt: event.message.updatedAt } : m,
        ),
      );
    }

    events.on('MESSAGE_RECEIVED', messageReceivedHander);
    events.on('MESSAGE_REACTION_RECEIVED', messageReactionReceivedHander);
    events.on('MESSAGE_STATUS_RECEIVED', messageStatusReceivedHander);
    return () => {
      events.off('MESSAGE_RECEIVED', messageReceivedHander);
      events.off('MESSAGE_REACTION_RECEIVED', messageReactionReceivedHander);
      events.off('MESSAGE_STATUS_RECEIVED', messageStatusReceivedHander);
    };
  }, [chat?.id, account.id, contact.id, setChatAsRead]);

  useEffect(() => {
    async function messageReactionSentHandler(event) {
      try {
        await sendReaction(account.id, contact.id, event.message.id, {
          reaction: event.message.reaction.emoji,
        });
        setMessages(msgs =>
          msgs.map(m =>
            m.id === event.message.id ? { ...m, reactions: mergeReactions(m, event.message.reaction) } : m,
          ),
        );
      } catch (error) {
        events.error({ description: 'Erro ao enviar reação' });
        sendErrorToNewRelic(error);
      }
    }

    events.on('MESSAGE_REACTION_SENT', messageReactionSentHandler);
    return () => {
      events.off('MESSAGE_REACTION_SENT', messageReactionSentHandler);
    };
  }, []);

  useEffect(() => {
    function messageSentHandler(event: MessageSent) {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({
          block: 'end',
          behavior: 'smooth',
        });
      }
      setMessages(msgs => [...msgs, event.message]);
    }

    events.on('MESSAGE_SENT', messageSentHandler);
    return () => {
      events.off('MESSAGE_SENT', messageSentHandler);
    };
  }, []);

  if (!messages) {
    return <Loading />;
  }

  return (
    <Box
      css={{
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: '$backgroundTertiary',
        backgroundImage: `url('${BackImage}')`,
        backgroundBlendMode: 'soft-light',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Flex
        css={{
          overflow: 'auto',
          height: '100%',
          position: 'relative',
          zIndex: 1,
        }}
        direction="column-reverse"
        id="messages-container"
        ref={messagesContainerRef}
      >
        <InfiniteScroll
          dataLength={messages.length}
          next={fetchData}
          hasMore={next !== undefined}
          loader={<Loading css={{ width: '100%', margin: '$4 0' }} />}
          scrollableTarget="messages-container"
          hasChildren={true}
          inverse={true}
        >
          <Grid gapY="$1" css={{ padding: '$4 $6 $10 $6' }} ref={messagesEndRef}>
            {messages.map(message => (
              <MessageSwitcher key={message.id} message={message} isChatSessionOpen={isChatSessionOpen} />
            ))}
          </Grid>
        </InfiniteScroll>
      </Flex>
    </Box>
  );
}

/**
 * Valida se o evento recebido é posterior ao status atual da mensagem.
 * Os status são enviados de forma assíncrona, e pode acontecer de um status chegar atrasado
 * em relação a um status posterior. Por exemplo, "sent" chegar depois do "delivered".
 * Esta função garante valida se devemos ou não atualizar o status da mensagem
 * @param message Dados atuais da mensagem
 * @param event Evento de status recebido
 * @returns 'true' caso seja necessário atualizar a mensagem
 */
function isStatusUpdate(message: Message, event: MessageStatusReceived): boolean {
  if (message.id !== event.message.id) {
    return false;
  }

  // Stored é o status inicial da mensagem. Podemos assumir que qualquer status será posterior a ele
  if (message.status === 'stored') {
    return true;
  }

  // Stored é o status final da mensagem. Podemos assumir que sempre é válido ele sobrescrever o status atual
  if (event.message.status === 'read') {
    return true;
  }

  // O status delivered só deve sobrescrever 'sent' e 'stored'. 'stored' já foi coberto no caso anterior, então
  // só precisamos validar se a mensagem está em 'sent'
  if (event.message.status === 'delivered' && message.status === 'sent') {
    return true;
  }

  return false;
}

function mergeReactions(message: Message, reaction: Reaction): Array<Reaction> | undefined {
  const messageReactionsList = [...(message?.reactions ? message.reactions : [reaction])];

  const reactions: Array<Reaction> = messageReactionsList.reduce<Array<Reaction>>((_, __, ___, arr) => {
    if (reaction.from === message.contact) {
      return [...arr.filter(r => r.from !== message.contact), reaction];
    }
    if (reaction.from !== message.contact) {
      return [...arr.filter(r => r.from === message.contact), reaction];
    }

    return [];
  }, messageReactionsList);

  return reactions.length > 0 ? reactions : undefined;
}
