import { useRef, useState } from 'react';

import { FormMessageSender } from '../FormMessageSender';
import { ToggleAudioRecorder } from '../AudioRecorder/ToggleAudioRecorder';
import { useMessageSenderFormSubmit } from '../../hooks/';

import { SendMessageData } from '@/shared/services/api/instant-messenger/service';
import { ImagePreview } from '../ImagePreviewFromPaste';

import { authService } from '@vdi/auth-service';

type TextFormMessageSenderProps = {
  onSendMessage(payload: SendMessageData): Promise<void>;
};

export function TextFormMessageSender({ onSendMessage }: TextFormMessageSenderProps) {
  const messageSenderRef = useRef<HTMLInputElement>(null);
  const [isAudioRecorderActive, setIsAudioRecorderActive] = useState(false);
  const [imageObjectURL, setImageObjectURL] = useState<string | undefined>(null);
  const [imageRawFile, setImageRawFile] = useState<File | undefined>(null);

  const hasImage = imageRawFile && imageObjectURL;

  const onFormSubmit = (formValue: { textMessage: string }) => {
    /*
      Não enviar mensagem se o objeto formValue estiver vazio
      Não enviar mensagem a propriedade textMessage do objeto formValue estiver vazio
      Pra enviar a mensagem é necessário que o objeto formValue tenha pelo menos a chave textMessage com um valor
    */
    const text = formValue?.textMessage;
    const hasText = text.length > 0;
    if (!hasText) return;

    const userName = authService.userData.name;
    const body = `*${userName}*:\n${text}`;

    onSendMessage({
      provider: 'whatsapp',
      type: 'text',
      text: {
        body,
      },
    });
  };

  const { currentValue, handleInputKeyDown, onSubmit, handleOnInput, setValue, resetTextMessage } =
    useMessageSenderFormSubmit({
      isRequired: true,
      ref: messageSenderRef,
      registerName: 'textMessage',
      onFormSubmit,
    });

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const text = event.clipboardData.getData('text/plain');
    const items = event.clipboardData.items;
    let hasImage = false;

    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        hasImage = true;
        const imgAsFile = item.getAsFile();
        setImageObjectURL(URL.createObjectURL(imgAsFile));
        setImageRawFile(imgAsFile);
        break;
      }
    }

    if (hasImage) return;

    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    range.deleteContents();

    const textNode = document.createTextNode(text);
    range.insertNode(textNode);

    range.setStartAfter(textNode);
    range.setEndAfter(textNode);
    selection.removeAllRanges();
    selection.addRange(range);
    setValue('textMessage', textNode.textContent, {
      shouldValidate: true,
    });
  };

  function resetImagePreview() {
    URL.revokeObjectURL(imageObjectURL);
    setImageObjectURL(null);
    setImageRawFile(null);
  }

  return (
    <>
      <ToggleAudioRecorder
        onSendMessage={onSendMessage}
        setIsAudioRecorderActive={setIsAudioRecorderActive}
        isAudioRecorderActive={isAudioRecorderActive}
        resetTextMessage={resetTextMessage}
      />
      {!isAudioRecorderActive && (
        <FormMessageSender
          currentValue={currentValue}
          formSubmit={onSubmit}
          handleInputKeyDown={handleInputKeyDown}
          handleOnInput={handleOnInput}
          messageSenderRef={messageSenderRef}
          handlePaste={handlePaste}
        />
      )}
      {hasImage && (
        <ImagePreview
          imageObjectURL={imageObjectURL}
          imageRawFile={imageRawFile}
          onSendMessage={onSendMessage}
          resetImagePreview={resetImagePreview}
        />
      )}
    </>
  );
}
