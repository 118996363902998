import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import usePrevious from 'react-use/lib/usePrevious';
import { FloraButton, Box, Flex, Image as FloraImage, Text } from '@grupoboticario/flora-react';
import { getMediaUrl } from '@/shared/services/api/instant-messenger/service/getMedia';
import { CrossCircleIcon, TrayArrowDownIcon } from '@grupoboticario/flora-react-icons';
import { ImageZoomedWrapper } from './ImageZoomed';
import useKey from 'react-use/lib/useKey';
import { useAccountStore } from '@/shared/state';

type ImageIdMessageProps = { id: string };
type ImageLinkMessageProps = { link: string };

type ImageMessageProps = (ImageIdMessageProps | ImageLinkMessageProps) & {
  caption?: string;
};

export const Image = memo((props: ImageMessageProps) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [imageOrientation, setImageOrientation] = useState<'portrait' | 'landscape' | 'square' | undefined>(undefined);
  const [bigImageToggle, setBigImageToggle] = useState(false);
  const [src, setSrc] = useState<string | undefined>(() => ('link' in props ? props.link : undefined));
  const prevId = usePrevious((props as ImageIdMessageProps).id);
  useKey('Escape', () => setBigImageToggle(false));
  const { account } = useAccountStore();

  async function fetchImage(mediaId: string) {
    const res = await getMediaUrl(account.id, mediaId);
    setSrc(res.url);
  }

  useEffect(
    function fetchImageWhenNoLinkProvided() {
      if (!src) {
        fetchImage((props as ImageIdMessageProps).id);
      }
    },
    [src],
  );

  useEffect(
    function fetchImageWhenIdChanges() {
      if ((props as ImageIdMessageProps).id !== prevId && prevId !== undefined) {
        fetchImage((props as ImageIdMessageProps).id);
      }
    },
    [prevId, (props as ImageIdMessageProps).id],
  );

  useLayoutEffect(
    function setImageOrientationFromNaturalSize() {
      if (imageRef.current) {
        const onLoad = () => {
          const { naturalWidth, naturalHeight } = imageRef.current!;
          if (naturalWidth && naturalHeight) {
            setImageOrientation(
              naturalWidth > naturalHeight ? 'landscape' : naturalWidth < naturalHeight ? 'portrait' : 'square',
            );
          }
        };
        imageRef.current.addEventListener('load', onLoad);
        return () => {
          imageRef.current!.removeEventListener('load', onLoad);
        };
      }
    },
    [src],
  );

  function ImageZoomedPortal() {
    const [isZoomedMore, setIsZoomedMore] = useState(false);
    const zoomedImageRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
      const zoomedImage = zoomedImageRef.current!;
      const { naturalWidth, naturalHeight } = zoomedImage;
      const { width, height } = zoomedImage.parentElement.getBoundingClientRect();
      const scale = Math.min(naturalWidth / width, naturalHeight / height);

      if (scale < 1) {
        zoomedImage.parentElement.style.transform = isZoomedMore ? `scale(${2})` : `scale(${1})`;
        zoomedImage.parentElement.style.zIndex = isZoomedMore ? `100` : `0`;
        zoomedImage.style.cursor = isZoomedMore ? `zoom-out` : `zoom-in`;
      }
    }, [isZoomedMore]);

    function handleMouseMove(e: React.MouseEvent<HTMLImageElement>) {
      const zoomedImage = zoomedImageRef.current;

      if (zoomedImage) {
        const { offsetWidth, offsetHeight } = e.currentTarget;
        const { offsetX, offsetY } = e.nativeEvent;
        const xPercent = `${(100 * offsetX) / offsetWidth}%`;
        const yPercent = `${(100 * offsetY) / offsetHeight}%`;

        zoomedImage.style.setProperty('--x', xPercent);
        zoomedImage.style.setProperty('--y', yPercent);
      }
    }

    async function downloadImage(e: React.MouseEvent<HTMLButtonElement>) {
      e.stopPropagation();
      const rawUrl = new URL(src);
      const image = await fetch(src);
      const imageBlob = await image.blob();
      const imageURL = URL.createObjectURL(imageBlob);
      const imageNameWithExtension = rawUrl.pathname.split('/').pop();

      const link = document.createElement('a');
      link.href = imageURL;
      link.download = imageNameWithExtension;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(imageURL);
    }

    return createPortal(
      <ImageZoomedWrapper onClick={() => setBigImageToggle(!bigImageToggle)}>
        <Box
          css={{
            boxShadow: '$downFar',
            border: '1px solid #ccc',
            overflow: 'hidden',
            borderRadius: '8px',
          }}
        >
          <FloraImage
            onMouseMove={handleMouseMove}
            onClick={e => {
              e.stopPropagation();
              setIsZoomedMore(!isZoomedMore);
            }}
            ref={zoomedImageRef}
            src={src}
            alt={props.caption}
            title={props.caption}
          />
        </Box>
        <FloraButton
          hierarchy="tertiary"
          has="iconOnly"
          aria-label="Fechar"
          css={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            color: '$nonInteractiveAuxiliar',
            padding: '5px',
            '&:hover': { backgroundColor: 'transparent' },
            '& svg': { width: '44px', height: '44px' },
          }}
          icon={<CrossCircleIcon />}
        />
        <FloraButton
          hierarchy="tertiary"
          has="iconOnly"
          aria-label="Download da imagem"
          onClick={downloadImage}
          css={{
            position: 'absolute',
            top: '1rem',
            left: '1rem',
            color: '$nonInteractiveAuxiliar',
            padding: '5px',
            '&:hover': { backgroundColor: 'transparent' },
            '& svg': { width: '44px', height: '44px' },
          }}
          icon={<TrayArrowDownIcon />}
        />
      </ImageZoomedWrapper>,
      document.querySelector('#image-gallery')!,
    );
  }

  function imageOrientationStyle() {
    if (imageOrientation === 'square') {
      return { maxWidth: '26vw' };
    }

    if (imageOrientation === 'landscape') {
      return { maxHeight: 'auto' };
    }

    return { maxHeight: '30vh' };
  }

  if (src) {
    return (
      <Flex direction="column" wrap="wrap" align="center" justify="center">
        <FloraImage
          ref={imageRef}
          src={src}
          alt={props.caption}
          title={props.caption}
          onClick={() => setBigImageToggle(!bigImageToggle)}
          css={{ cursor: 'zoom-in', ...imageOrientationStyle() }}
        />
        <Text>{props.caption}</Text>
        {bigImageToggle && <ImageZoomedPortal />}
      </Flex>
    );
  }

  return null;
});
