import { useEffect, useState } from 'react';

import { ContactSearch } from '@/features/ContactSearch';
import { ContactList } from '@/features/ContactList';
import { MessagesList } from '@/features/MessagesList';

import { globalStyles } from '@/shared/styles/globalStyles';
import { getResellerData } from '@/shared/services/api/instant-messenger/service/getResellerData';
import { events } from '@/shared/services/events';
import { Reseller } from '@/shared/types/interfaces';
import { useNotifications } from '@/shared/hooks';
import { getErrorMessage } from '@/shared/functions/errors';
import {
  AppContainer,
  ChatWrapper,
  ContactsWrapper,
  MessagesListWrapper,
  ContactSearchWrapper,
  Notify,
} from '@/shared/components';

export default function Root() {
  globalStyles();
  useNotifications();
  const [contact, setContact] = useState<Reseller | undefined>();
  const searchParams = new URLSearchParams(window.location.search);
  const urlContact = searchParams.get('contact');

  async function fetchContact(geraId: string) {
    if (!geraId) {
      return;
    }
    try {
      const contact = await getResellerData(geraId);
      setContact(contact);
    } catch (err) {
      const msg = await getErrorMessage(err);
      events.error({ description: msg });
    }
  }

  function onSelectContact(contact: Reseller) {
    setContact(contact);
  }

  useEffect(() => {
    if (urlContact) {
      history.replaceState('', '', '/chat');
      fetchContact(urlContact);
    }
  }, [urlContact]);

  return (
    <AppContainer as="main">
      <Notify />
      <ChatWrapper>
        <ContactsWrapper data-testid="left-column">
          <ContactSearchWrapper>
            <ContactSearch onSubmit={fetchContact} />
          </ContactSearchWrapper>
          <ContactList contact={contact} onSelect={onSelectContact} />
        </ContactsWrapper>

        <MessagesListWrapper data-testid="right-column">
          <MessagesList contact={contact} />
        </MessagesListWrapper>
      </ChatWrapper>
      <div id="image-gallery"></div>
      <div id="image-upload-preview"></div>
    </AppContainer>
  );
}
