import { fetcher } from '../../fetcher';

type SendReactionRequest = {
  reaction: string;
};

export async function sendReaction(contactId: string, messageId: string, body: SendReactionRequest): Promise<void> {
  await fetcher.patch(`v1/messages/${contactId}/${messageId}`, {
    json: body,
  });
}
