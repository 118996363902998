import { Button } from '@grupoboticario/flora-react';
import { CrossCircleIcon } from '@grupoboticario/flora-react-icons';
import type { ImageItem } from './types';

type ButtonCloseProps = {
  setImagePreviewList: React.Dispatch<React.SetStateAction<Array<ImageItem>>>;
  isUploadingMedia: boolean;
};

export function ButtonClose({ setImagePreviewList, isUploadingMedia }: ButtonCloseProps) {
  return (
    <Button
      disabled={isUploadingMedia}
      onClick={() => setImagePreviewList([])}
      hierarchy="tertiary"
      has="iconOnly"
      aria-label="Fechar"
      css={{
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        color: '$nonInteractiveAuxiliar',
        '&:hover': { backgroundColor: 'transparent' },
        '& svg': { width: '44px', height: '44px' },
        zIndex: '200',
      }}
      icon={<CrossCircleIcon />}
    />
  );
}
