import { useQuery } from '@tanstack/react-query';
import { getTags } from '@/shared/services/api/instant-messenger/service';

export function useFetchTagList({ enabled }: { enabled: boolean }) {
  return useQuery({
    queryKey: ['tags'],
    queryFn: getTags,
    enabled,
    initialData: {
      data: [],
    },
  });
}
