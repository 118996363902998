import { memo, useEffect, useState } from 'react';
import usePrevious from 'react-use/lib/usePrevious';
import { getMediaUrl } from '@/shared/services/api/instant-messenger/service/getMedia';
import { useAccountStore } from '@/shared/state';

type VideoIdMessageProps = { id: string };
type VideoLinkMessageProps = { link: string };

type VideoMessageProps = (VideoIdMessageProps | VideoLinkMessageProps) & {
  caption?: string;
};

export const Video = memo((props: VideoMessageProps) => {
  const [src, setSrc] = useState<string | undefined>(() => ('link' in props ? props.link : undefined));
  const prevId = usePrevious((props as VideoIdMessageProps).id);
  const { account } = useAccountStore();

  async function fetchVideo(mediaId: string) {
    const res = await getMediaUrl(account.id, mediaId);
    setSrc(res.url);
  }

  useEffect(
    function fetchVideoWhenNoLinkProvided() {
      if (!src) {
        fetchVideo((props as VideoIdMessageProps).id);
      }
    },
    [src],
  );

  useEffect(
    function fetchVideoWhenIdChanges() {
      if ((props as VideoIdMessageProps).id !== prevId && prevId !== undefined) {
        fetchVideo((props as VideoIdMessageProps).id);
      }
    },
    [prevId, (props as VideoIdMessageProps).id],
  );

  if (src) {
    return (
      <video src={src} controls style={{ maxWidth: '100%' }}>
        <track kind="captions" srcLang="pt-BR" label="Portuguese" />
      </video>
    );
  }

  return null;
});
