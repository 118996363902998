import { Button } from '@grupoboticario/flora-react';
import { tilt } from './tiltKeyframes';

type MediaUploaderButtonProps = {
  IconComponent: React.ReactElement;
  htmlFor: string;
  ariaLabel: string;
};

export default function MediaUploaderButton({ IconComponent, htmlFor, ariaLabel }: MediaUploaderButtonProps) {
  return (
    <Button
      hierarchy="tertiary"
      has="iconOnly"
      icon={IconComponent}
      htmlFor={htmlFor}
      as="label"
      aria-label={ariaLabel}
      css={{
        color: '#444',
        transition: 'all 200ms ease-in-out',
        '&:hover': {
          color: '$actionNavigationStandardStatic',
          animation: `${tilt} 0.2s ease-in-out`,
          backgroundColor: 'transparent',
        },
        '& svg': {
          width: '24px',
          height: '24px',
        },
        padding: '11px',
      }}
    />
  );
}
