import { useCallback } from 'react';
import { Flex } from '@grupoboticario/flora-react';
import { MessageWrapper } from '../MessageWrapper';
import { MessageReplyIcon } from './MessageReplyIcon';
import { MessageTypesSwitcher } from '../MessageTypesSwitcher/MessageTypesSwitcher';

import { events } from '@/shared/services/events';
import { Reseller } from '@/shared/types/interfaces';
import { Message } from '@/shared/services/api/instant-messenger/types';
import { isAMessageFromSelectedContact } from '@/shared/functions';
import { ReplyerName } from './ReplyerName';
import { MessageReactionIcon } from './MessageReactionIcon';

interface MessageSwitcherProps {
  message: Message;
  contact: Reseller;
  isChatSessionOpen: boolean;
}

export const MessageSwitcher = ({ message, contact, isChatSessionOpen }: MessageSwitcherProps): JSX.Element => {
  const selectMessage = useCallback(() => {
    events.emit('MESSAGE_SELECTED', message);
  }, [message]);
  const reactionSentFromMe = message?.reactions?.find(reaction => reaction.from !== message.contact);

  return (
    <Flex
      css={{
        justifyContent: `${isAMessageFromSelectedContact(message) ? 'start' : 'end'}`,
        paddingBottom: `${message.reactions && '$4'} `,
        '&:hover .message-reaction-icon': {
          opacity: '1 !important',
          visibility: 'visible !important',
        },
      }}
    >
      <MessageWrapper
        status={message.status}
        createdAt={message.createdAt}
        reactions={message.reactions}
        isAMessageFromSelectedContact={isAMessageFromSelectedContact(message)}
      >
        <MessageReplyIcon onClick={selectMessage} isChatSessionOpen={isChatSessionOpen} />
        <MessageReactionIcon
          isAMessageFromSelectedContact={isAMessageFromSelectedContact(message)}
          messageId={message.id}
          reactionSentFromMe={reactionSentFromMe}
          isChatSessionOpen={isChatSessionOpen}
        />
        {message?.reply && (
          <Flex
            direction="column"
            wrap="nowrap"
            css={{
              backgroundColor: 'rgb(244, 245, 246)',
              margin: '-8px -8px 0 -8px',
              padding: '8px',
              borderRadius: '5px',
              borderLeft: `4px solid ${isAMessageFromSelectedContact(message.reply) ? '$actionableDefault' : '$nonInteractiveEmphasis'}`,
              color: 'rgb(102, 119, 129)',
              fontSize: '14px',
            }}
          >
            <ReplyerName
              isAMessageFromSelectedContact={isAMessageFromSelectedContact(message.reply)}
              contactName={contact.name}
            />
            <MessageTypesSwitcher message={message.reply} />
          </Flex>
        )}
        <Flex direction="column" wrap="nowrap">
          <MessageTypesSwitcher message={message} />
        </Flex>
      </MessageWrapper>
    </Flex>
  );
};
