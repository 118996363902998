import { fetcher } from '@/shared/services/api/fetcher';
import { Message, TemplateParamsByType } from '../types';

export type SendMessageData = {
  provider: 'whatsapp';
  reply?: Message;
} & (
  | {
      type: 'document';
      document: {
        id: string;
        filename: string;
      };
    }
  | {
      type: 'video';
      video: {
        id: string;
      };
    }
  | {
      type: 'audio';
      audio: {
        id: string;
      };
    }
  | {
      type: 'text';
      text: {
        body: string;
      };
    }
  | {
      type: 'image';
      image: {
        id: string;
      };
    }
  | {
      type: 'rawTemplate';
      rawTemplate: {
        name: string;
        components: TemplateParamsByType[];
      };
    }
);

export type SendMessageResponse = {
  id: string;
  chat: {
    id: string;
  };
};

export async function sendMessage(
  accountId: string,
  contactId: string,
  data: SendMessageData,
): Promise<SendMessageResponse> {
  const res = await fetcher.post(`v1/accounts/${accountId}/messages/${contactId}`, {
    json: data.reply
      ? {
          ...data,
          reply: {
            id: data.reply.id,
          },
        }
      : data,
  });

  return res.json() as Promise<SendMessageResponse>;
}
