import { Fragment, useEffect, useState } from 'react';
import { Box, FloraButton, Card, Flex, FloraTypography } from '@grupoboticario/flora-react';
import { ChatBubbleOutlineIcon, ChevronRightIcon, LockIcon } from '@grupoboticario/flora-react-icons';
import { Menu, MenuItem, MenuDivider, MenuItemProps } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import { richTextIntoHTML } from '../../format/';

import { getTemplates } from '@/shared/services/api/instant-messenger/service';
import { Template } from '@/shared/types/interfaces';
import { useSendMessage } from '@/shared/hooks';
import { events } from '@/shared/services/events';
import { sendErrorToNewRelic } from '@/shared/functions/newRelic';
import { useAccountStore, useContactStore } from '@/shared/state';

export function TemplateFormMessageSender() {
  const [templates, setTemplates] = useState<Array<Template>>([]);
  const { account } = useAccountStore();
  const { contact } = useContactStore();
  const { onSendMessage } = useSendMessage(account, contact);

  async function sendChosenTemplate(event: MenuItemProps) {
    try {
      await onSendMessage({
        provider: 'whatsapp',
        type: 'rawTemplate',
        rawTemplate: {
          name: event.value,
          components: [],
        },
      });
    } catch (error) {
      events.error({ description: 'Erro ao enviar template' });
      sendErrorToNewRelic(error);
    }
  }

  useEffect(() => {
    async function fetchTemplates() {
      try {
        const templates = await getTemplates(account.id);
        setTemplates(templates.data);
      } catch (error) {
        events.error({ description: 'Erro ao buscar templates' });
        sendErrorToNewRelic(error);
      }
    }

    fetchTemplates();
  }, []);

  return (
    <Flex justify="center">
      <Menu
        overflow="auto"
        position="initial"
        arrow
        align="center"
        direction="top"
        menuButton={
          <Card variant="active" css={{ margin: '1rem 0' }}>
            <Flex direction="row" justify="center" align="center">
              <LockIcon color="$9" />
              <FloraTypography
                as="span"
                fontSize="bodySmallStandard"
                css={{ margin: '0 1rem 0 .5rem', lineHeight: '1.2em' }}
              >
                A janela de conversa com este revendedor expirou.
                <br /> Use um dos modelos para iniciar uma conversa.
              </FloraTypography>
              <FloraButton hierarchy="primary" styleSemantic="success" has="iconRight" icon={<ChevronRightIcon />}>
                Selecionar modelo
              </FloraButton>
            </Flex>
          </Card>
        }
        transition
        onItemClick={e => sendChosenTemplate(e)}
      >
        {templates.map((t, index) => {
          return (
            <Fragment key={`${t.name}-${index}`}>
              <MenuItem value={t.name}>
                <Box css={{ maxWidth: '500px' }}>
                  <Flex direction="row" align="center">
                    <ChatBubbleOutlineIcon color="$actionableDefault" css={{ marginRight: '.5rem' }} />
                    <FloraTypography fontSize="exceptionsAuxiliar" as="h6" css={{ marginBottom: '3px' }}>
                      {t.title}
                    </FloraTypography>
                  </Flex>
                  {/*
                    No momento os templates suportados só possuem um elemento de BODY
                    Então é aceitável passar direto o campo texto do index "zero"
                    */}
                  <FloraTypography
                    fontSize="bodyLargeStandard"
                    as="span"
                    dangerouslySetInnerHTML={{
                      __html: richTextIntoHTML(t.components[0].text),
                    }}
                  ></FloraTypography>
                </Box>
              </MenuItem>
              {index !== templates.length - 1 && <MenuDivider />}
            </Fragment>
          );
        })}
      </Menu>
    </Flex>
  );
}
