import { httpClientInstance } from '@/shared/services/api';

export interface GetMediaResponse {
  url: string;
}

export async function getMediaUrl(id: string): Promise<GetMediaResponse> {
  const res = await httpClientInstance(`v1/medias/${id}`, {
    method: 'GET',
    redirect: 'manual',
  });

  return res.json() as Promise<GetMediaResponse>;
}
