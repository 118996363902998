import { fetcher } from '@/shared/services/api/fetcher';
import { GetTemplatesResponse, Template } from '@/shared/types/interfaces';

export async function getTemplates(): Promise<GetTemplatesResponse> {
  const uri = `v1/templates`;
  return fetcher.get<GetTemplatesResponse>(uri, {});
}

export async function findTemplate(name: string): Promise<Template> {
  const uri = `v1/templates/${name}`;
  return fetcher.get<Template>(uri, {});
}
