import { memo, useEffect, useState } from 'react';
import usePrevious from 'react-use/lib/usePrevious';
import { Image } from '@grupoboticario/flora-react';
import { getMediaUrl } from '@/shared/services/api/instant-messenger/service/getMedia';
import { useAccountStore } from '@/shared/state';

type StickerIdMessageProps = { id: string };
type StickerLinkMessageProps = { link: string };

type StickerMessageProps = StickerIdMessageProps | StickerLinkMessageProps;

export const Sticker = memo((props: StickerMessageProps) => {
  const [src, setSrc] = useState<string | undefined>(() => ('link' in props ? props.link : undefined));
  const prevId = usePrevious((props as StickerIdMessageProps).id);
  const { account } = useAccountStore();

  async function fetchSticker(mediaId: string) {
    const res = await getMediaUrl(account.id, mediaId);
    setSrc(res.url);
  }

  useEffect(
    function fetchStickerWhenNoLinkProvided() {
      if (!src) {
        fetchSticker((props as StickerIdMessageProps).id);
      }
    },
    [src],
  );

  useEffect(
    function fetchStickerWhenIdChanges() {
      if ((props as StickerIdMessageProps).id !== prevId && prevId !== undefined) {
        fetchSticker((props as StickerIdMessageProps).id);
      }
    },
    [prevId, (props as StickerIdMessageProps).id],
  );

  if (src) {
    return (
      <Image src={src} alt="Sticker" size={190} /> //css={{ animationIterationCount: '3' }}
    );
  }

  return null;
});
