import { fetcher } from '@/shared/services/api/fetcher';
import { MessengerResponse } from '@/shared/services/api/instant-messenger/types';

export interface GetMessagesRequest {
  contactId: string;
  next?: string;
}

export async function getMessages(accountId: string, query: GetMessagesRequest): Promise<MessengerResponse> {
  let url = `v1/accounts/${accountId}/messages/${query.contactId}`;
  if (query.next) {
    url += `?next=${query.next}`;
  }
  return fetcher.get<MessengerResponse>(url, {});
}
