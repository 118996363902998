import { useCallback, useEffect, useState } from 'react';
import { updateChat } from '@/shared/services/api/instant-messenger/service/updateChat';
import { sendErrorToNewRelic } from '@/shared/functions/newRelic';

export function useSetChatAsRead(chatId: string | undefined) {
  const [isPageVisible, setIsPageVisible] = useState(true);
  const [hasPendingMessagesToRead, setHasPendingMessagesToRead] = useState(false);

  const setChatAsRead = useCallback(() => {
    if (isPageVisible && chatId) {
      try {
        return updateChat(chatId, { status: 'read' });
      } catch (error) {
        sendErrorToNewRelic(error);
      }
    }

    setHasPendingMessagesToRead(true);
  }, [chatId, isPageVisible]);

  useEffect(() => {
    async function visibilityChangeHandler() {
      const isVisible = !document.hidden;
      setIsPageVisible(isVisible);
    }

    document.addEventListener('visibilitychange', visibilityChangeHandler);
    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
    };
  }, [chatId]);

  useEffect(() => {
    if (isPageVisible && hasPendingMessagesToRead) {
      setHasPendingMessagesToRead(false);
      setChatAsRead();
    }
  }, [chatId, isPageVisible]);

  return { setChatAsRead };
}
