import { getAccounts } from '../services/api/instant-messenger/service/getAccounts';
import { useQuery } from '@tanstack/react-query';
import { useAccountStore } from '../state';

export const useFetchAccounts = ({ enabled }: { enabled: boolean }) => {
  const { account, setAccount } = useAccountStore();

  return useQuery({
    queryKey: ['accounts'],
    queryFn: getAccounts,
    enabled,
    select(data) {
      if (!account) {
        setAccount(data.data[0]);
      }
      return data.data[0];
    },
  });
};
