import { useRef } from 'react';

import { Button, Input } from '@grupoboticario/flora-react';
import { ArrowRightIcon } from '@grupoboticario/flora-react-icons';
import { ContactSearchForm } from './ContactSearchForm.styled';

type SearchFormProps = {
  onSubmit: (geraId: string) => void;
};

export const ContactSearch = ({ onSubmit }: SearchFormProps) => {
  const inputRef = useRef<HTMLInputElement>();

  const searchContact = () => {
    onSubmit(inputRef.current.value);
  };

  return (
    <ContactSearchForm>
      <Input
        id="searchResellerField"
        role="textbox"
        label="Buscar por código revendedor (VD+)"
        ref={inputRef}
        rightIcon={
          <Button
            hierarchy="tertiary"
            size="small"
            has="iconOnly"
            type="submit"
            aria-label={'Criar novo chat'}
            role="button"
            icon={<ArrowRightIcon />}
            onClick={event => {
              event.preventDefault();
              searchContact();
            }}
          />
        }
        onKeyUp={event => {
          if (event.key === 'Enter') {
            searchContact();
          }
        }}
        autoComplete="off"
        maxLength={10}
      />
    </ContactSearchForm>
  );
};
