import { fetcher } from '@/shared/services/api/fetcher';

type UpdateChatRequest = {
  status: 'read';
};

export async function updateChat(contactId: string, body: UpdateChatRequest): Promise<void> {
  await fetcher.patch(`v1/chats/${contactId}`, {
    json: body,
  });
}
