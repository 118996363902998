import { Grid } from '@grupoboticario/flora-react';

import { BlankState } from './components/BlankState';
import { MessagesContainer } from './components/MessagesContainer';

import { MessageSender } from '@/features/MessageSender';
import { ContactInfo } from '@/features/ContactInfo';
import { Reseller } from '@/shared/types/interfaces';
import { sendMessage, SendMessageData } from '@/shared/services/api/instant-messenger/service';
import { events } from '@/shared/services/events';
import { useCallback, useEffect, useState } from 'react';
import { MessageReceived } from '@/shared/services/api/instant-messenger/types';

type InstantMessengerProps = {
  contact?: Reseller;
};

export function MessagesList({ contact }: InstantMessengerProps) {
  const [isChatSessionOpen, setIsChatSessionOpen] = useState(false);

  useEffect(() => {
    if (contact?.whatsAppSessionExp) {
      return setIsChatSessionOpen(new Date(contact.whatsAppSessionExp).getTime() > Date.now());
    }
    setIsChatSessionOpen(false);
  }, [contact]);

  useEffect(() => {
    function messageReceivedHander(event: MessageReceived) {
      if (event.contact.id === contact.id) {
        setIsChatSessionOpen(true);
      }
    }

    events.on('MESSAGE_RECEIVED', messageReceivedHander);
    return () => {
      events.off('MESSAGE_RECEIVED', messageReceivedHander);
    };
  }, [contact]);

  const onSendMessage = useCallback(
    async (payload: SendMessageData): Promise<void> => {
      const res = await sendMessage(contact.id, payload);
      const now = new Date().toISOString();
      events.emit('MESSAGE_SENT', {
        contact,
        message: {
          id: res.id,
          ...payload,
          // TODO: não temos como preencher estes dados corretamente hoje
          // Validar se é possível que o retorno do IM traga mais informações
          // O importante aqui é que o "to" seja igual o "contact" para que a mensagem seja renderizada no lado correto
          contact: 'reseller',
          createdAt: now,
          from: 'vdi',
          reply: payload.reply,
          status: 'stored',
          to: 'reseller',
          updatedAt: now,
        },
      });
    },
    [contact],
  );

  if (!contact) {
    return <BlankState />;
  }

  return (
    <Grid key={contact.id} templateRows="auto 1fr auto" css={{ height: '100dvh', position: 'relative' }}>
      <ContactInfo contact={contact} />
      <MessagesContainer
        contact={contact}
        isChatSessionOpen={isChatSessionOpen}
        setIsChatSessionOpen={setIsChatSessionOpen}
      />
      <MessageSender contact={contact} onSendMessage={onSendMessage} isChatSessionOpen={isChatSessionOpen} />
      <div id="uploader-previewer" />
    </Grid>
  );
}
