import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Flex } from '@grupoboticario/flora-react';
import { SmileCircleIcon } from '@grupoboticario/flora-react-icons';
import useClickAway from 'react-use/lib/useClickAway';

import { events } from '@/shared/services/events';
import { Reaction } from '@/shared/services/api/instant-messenger/types';

type MessageReactionProps = {
  isAMessageFromSelectedContact: boolean;
  messageId: string;
  reactionSentFromMe: Reaction | undefined;
  isChatSessionOpen: boolean;
};

const REACTION_LIST = [
  { emoji: '👍', selected: false },
  { emoji: '❤️', selected: false },
  { emoji: '😂', selected: false },
  { emoji: '😮', selected: false },
  { emoji: '😢', selected: false },
  { emoji: '🙏', selected: false },
];

export function MessageReactionIcon({
  isAMessageFromSelectedContact,
  messageId,
  reactionSentFromMe,
  isChatSessionOpen,
}: MessageReactionProps) {
  const isFirstRender = useRef(true);
  const buttonToOpenReactionSelection = useRef(null);
  const [isMessageReactionOpen, setIsMessageReactionOpen] = useState(false);
  const [reactions, setReactions] = useState(() =>
    REACTION_LIST.map(reaction => ({
      ...reaction,
      selected: reactionSentFromMe?.emoji === reaction.emoji,
    })),
  );
  useClickAway(buttonToOpenReactionSelection, () => {
    setIsMessageReactionOpen(false);
  });

  const openMessageReactionElement = () => {
    setIsMessageReactionOpen(!isMessageReactionOpen);
  };

  const handleReaction = useCallback(e => {
    setReactions(reactions =>
      reactions.map(reaction => ({
        ...reaction,
        selected: reaction.emoji === e.target.textContent ? !reaction.selected : false,
      })),
    );
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    events.emit('MESSAGE_REACTION_SENT', {
      message: {
        id: messageId,
        reaction: {
          date: new Date().toISOString(),
          emoji: reactions.find(reaction => reaction.selected)?.emoji ?? '',
          from: 'me',
        },
      },
    });
  }, [reactions, messageId]);

  if (!isChatSessionOpen) {
    return null;
  }

  return (
    <>
      <div
        onClick={openMessageReactionElement}
        ref={buttonToOpenReactionSelection}
        className="message-reaction-icon"
        style={{
          position: 'absolute',
          top: 'calc(50% - 16px)',
          [`${isAMessageFromSelectedContact ? 'left' : 'right'}`]: 'calc(100% + 6px)',
          opacity: 0,
          visibility: 'hidden',
        }}
      >
        <Button
          hierarchy="tertiary"
          styleSemantic="neutral"
          size="small"
          css={{ borderRadius: '$pill', '&:hover': { backgroundColor: 'transparent' }, padding: 0 }}
          has="iconOnly"
          icon={
            <SmileCircleIcon
              size={18}
              css={{
                background: '#ccc',
                borderRadius: '$pill',
                padding: '$1',
                color: '#666',
                '& > svg': {
                  width: '16px',
                  height: '16px',
                },
              }}
            />
          }
        />

        <Flex
          direction="row"
          gapX={4}
          css={{
            position: 'absolute',
            [`${isAMessageFromSelectedContact ? 'left' : 'right'}`]: 'calc(100% - 140px)',
            top: 'calc(50% - 68px)',
            backgroundColor: '$backgroundPrimary',
            minWidth: '200px',
            padding: '$2',
            borderRadius: '24px',
            boxShadow: '$downClose',
            opacity: isMessageReactionOpen ? 1 : 0,
            visibility: isMessageReactionOpen ? 'visible' : 'hidden',
            height: '52px',
          }}
        >
          {reactions.map(reaction => {
            return (
              <Button
                key={reaction.emoji}
                onClick={handleReaction}
                hierarchy="tertiary"
                styleSemantic="neutral"
                size="small"
                css={{
                  borderRadius: '$pill',
                  fontSize: '1.25rem',
                  backgroundColor: reaction.selected ? '#ccc' : 'transparent',
                  width: '36px',
                  height: '36px',
                }}
              >
                {reaction.emoji}
              </Button>
            );
          })}
        </Flex>
      </div>
    </>
  );
}
