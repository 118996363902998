import { useCallback } from 'react';
import { sendMessage, SendMessageData } from '../services/api/instant-messenger/service';
import { events } from '../services/events';
import { Account, Reseller } from '../types/interfaces';

export const useSendMessage = (account: Account, contact: Reseller) => {
  const onSendMessage = useCallback(
    async (payload: SendMessageData): Promise<void> => {
      const res = await sendMessage(account.id, contact.id, payload);

      const now = new Date().toISOString();
      events.emit('MESSAGE_SENT', {
        account,
        chat: res.chat,
        contact,
        message: {
          id: res.id,
          ...payload,
          contact: 'reseller',
          createdAt: now,
          from: 'vdi',
          reply: payload.reply,
          status: 'stored',
          to: 'reseller',
          updatedAt: now,
        },
      });
    },
    [account.id, contact.id],
  );

  return { onSendMessage };
};
