import { Flex } from '@grupoboticario/flora-react';

import { ContactInfoTitle, ContactInfoWrapper, ContactCode } from './components';

import { Reseller } from '@/shared/types/interfaces';
import { formatCurrentCycle, toCurrency } from '@/shared/functions';
import { ActivityCycleTag } from '../ContactList/components';
import { useResellerCredits } from './hooks/useResellerCredits';

type ContactInfoProps = {
  contact: Reseller;
};

export function ContactInfo({ contact }: ContactInfoProps) {
  const credits = useResellerCredits(contact.vdId);

  return (
    <ContactInfoWrapper direction="row" align="center" gapX="$6">
      <Flex direction="column">
        <ContactInfoTitle as="h3">Contato</ContactInfoTitle>
        <Flex direction="row">
          <ActivityCycleTag currentCycle={contact.currentCycle}>
            {formatCurrentCycle(contact.currentCycle)}
          </ActivityCycleTag>
          <span
            title={contact.name}
            style={{ width: '120px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
          >
            {contact.name}
          </span>
        </Flex>
      </Flex>
      <Flex direction="column">
        <ContactInfoTitle as="h3">Código VD+</ContactInfoTitle>
        <ContactCode>{contact.vdId}</ContactCode>
      </Flex>

      <Flex direction="column">
        <ContactInfoTitle as="h3">Crédito liberado com tolerância</ContactInfoTitle>
        <ContactCode>{toCurrency(credits?.availableCredit ?? 0)}</ContactCode>
      </Flex>
      <Flex direction="column">
        <ContactInfoTitle as="h3">Crédito total com tolerância</ContactInfoTitle>
        <ContactCode>{toCurrency(credits?.totalCredit ?? 0)}</ContactCode>
      </Flex>
      <Flex direction="column">
        <ContactInfoTitle as="h3">Saldo CCR</ContactInfoTitle>
        <ContactCode>{toCurrency(credits?.balanceCCR ?? 0)}</ContactCode>
      </Flex>
    </ContactInfoWrapper>
  );
}
