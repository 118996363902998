import { styled, Text, TextProps } from '@grupoboticario/flora-react';
import { FC } from 'react';

export const ActivityCycleTag = styled(Text, {
  width: '24px',
  marginRight: '$2',
  padding: '$1',
  color: '#000',
  borderLeftWidth: '3px',
  borderLeftStyle: 'solid',
  backgroundColor: '#eee',
  fontVariant: 'tabular-nums',
  fontFamily: 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important',
  fontSize: '11px',
  borderRadius: '4px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  variants: {
    currentCycle: {
      A0: { borderLeftColor: '#ccc' },
      A1: { borderLeftColor: '$assorted6' },
      A2: { borderLeftColor: '$assorted5' },
      A3: { borderLeftColor: '$assorted4' },
      I4: { borderLeftColor: '$assorted3' },
      I5: { borderLeftColor: '$assorted2' },
      I6: { borderLeftColor: '$assorted1' },
      I7: { borderLeftColor: '#444' },
      null: {
        borderLeftColor: 'transparent',
        paddingLeft: '2px',
      },
      '': {
        borderLeftColor: 'transparent',
        paddingLeft: '2px',
      },
    },
  },
}) as FC<TextProps & { currentCycle: string | null }>;
