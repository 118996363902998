import { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Flex, Text } from '@grupoboticario/flora-react';
import { useReadAllNotificationsFromAGroup } from '@vdi/navbar';

import { MessageDateTime } from '@/features/MessagesList';

import {
  ContactItem,
  ContactLastMessageDateTime,
  ContactLastMessageExcerptSwitcher,
  ContactListWrapper,
  ContactName,
  ActivityCycleTag,
} from './components';

import { Loading } from '@/shared/components';
import { Chat, ChatMessage, Reseller } from '@/shared/types/interfaces';
import { getChats } from '@/shared/services/api/instant-messenger/service/getChats';
import { events } from '@/shared/services/events';
import {
  MessageReactionReceived,
  MessageReceived,
  MessageSent,
  ServiceRequestReceived,
} from '@/shared/services/api/instant-messenger/types';
import { useFixedScroll } from '@/shared/hooks';
import { formatCurrentCycle } from '@/shared/functions';

type ContactListProps = {
  contact: Reseller | undefined;
  onSelect: (contact: Reseller) => void;
};

export const ContactList = ({ contact, onSelect }: ContactListProps) => {
  const [chats, setChats] = useState<Array<Chat>>([]);
  const [nextPageId, setNextPage] = useState<string | undefined>();
  const [isLoadingChats, setIsLoadingChats] = useState(false);
  const isFirstFetch = useRef(true);
  const chatListEl = useRef<HTMLDivElement>(null);
  const { mutate: readAllNotificationsFromAGroup } = useReadAllNotificationsFromAGroup();

  const fetchData = useCallback(async () => {
    try {
      setIsLoadingChats(true);
      const res = await getChats(nextPageId);
      const nextPage = res.meta?.next;
      setChats(chats => [...chats, ...res.data]);
      setNextPage(nextPage);
    } catch (error) {
      console.error('Erro ao consultar seus contatos', error);
      events.error({ description: 'Erro ao consultar seus contatos' });
    } finally {
      isFirstFetch.current = false;
      setIsLoadingChats(false);
    }
  }, [nextPageId]);

  /**
   * Carregamento inicial de chats do usuário
   */
  useEffect(() => {
    fetchData();
  }, []);

  useFixedScroll({ el: chatListEl, hasMore: nextPageId, fetchData });

  function receiveMessageHandler(event: MessageReceived<ChatMessage> | MessageSent) {
    const chatIndex = chats.findIndex(c => c.contact.id === event.contact.id);
    const status = event.contact.id !== contact?.id ? ('unread' as const) : ('read' as const);
    const receivedChat = {
      contact: event.contact,
      message: event.message,
      status,
    };
    if (chatIndex === -1) {
      setChats(chats => [receivedChat, ...chats]);
    } else {
      setChats(chats => {
        const copy = [...chats];
        // Remove o item da posição atual
        copy.splice(chatIndex, 1);
        // Insere na primeira posição
        copy.unshift(receivedChat);
        return copy;
      });
    }

    // Seleciona o próprio contato para atualizar o campo de janela WhatsApp
    if (contact?.id === event.contact.id) {
      onSelect(event.contact);
    }
  }

  /**
   * Recebimento de mensagens em tempo real
   * Atualiza o item da lista com a informação mais recente
   */
  useEffect(() => {
    function handleReactionReceived(event: MessageReactionReceived) {
      if (!event.message.reaction.emoji) {
        return;
      }

      receiveMessageHandler({
        ...event,
        message: {
          type: 'reaction',
          createdAt: event.message.reaction.date,
          emoji: event.message.reaction.emoji,
        },
      });
    }

    function handleServiceRequest(event: ServiceRequestReceived) {
      receiveMessageHandler({
        ...event,
        message: {
          type: 'service-request',
          createdAt: event.service.createdAt,
        },
      });
    }

    events.on('MESSAGE_RECEIVED', receiveMessageHandler);
    events.on('MESSAGE_REACTION_RECEIVED', handleReactionReceived);
    events.on('MESSAGE_SENT', receiveMessageHandler);
    events.on('SERVICE_REQUEST_RECEIVED', handleServiceRequest);
    return () => {
      events.off('MESSAGE_RECEIVED', receiveMessageHandler);
      events.off('MESSAGE_REACTION_RECEIVED', handleReactionReceived);
      events.off('MESSAGE_SENT', receiveMessageHandler);
      events.off('SERVICE_REQUEST_RECEIVED', handleServiceRequest);
    };
  }, [chats, contact]);

  function patchGroupOfMessagesAsRead(chatStatus: Chat['status'], vdId: Reseller['vdId']) {
    if (chatStatus === 'read') {
      return;
    }

    const CONTACT_NOTIFICATION_GROUP = `contact-${vdId}`;
    readAllNotificationsFromAGroup({ groupId: CONTACT_NOTIFICATION_GROUP });
  }

  function setChatAsRead(chat: Chat) {
    const index = chats.indexOf(chat);
    const copy = [...chats];
    copy.splice(index, 1, { ...chat, status: 'read' });
    setChats(copy);
  }

  if (isLoadingChats && isFirstFetch.current) {
    return <Loading css={{ width: '100%', height: '100%' }} />;
  }

  return (
    <ContactListWrapper id="reseller-list" role="listbox" aria-label="results list" ref={chatListEl}>
      <InfiniteScroll
        dataLength={chats.length}
        next={fetchData}
        hasMore={nextPageId !== undefined}
        loader={<Loading css={{ width: '100%', margin: '$4 0' }} />}
        scrollableTarget="reseller-list"
        hasChildren
      >
        {chats.map((chat, index) => (
          <ContactItem
            selected={chat.contact.id === contact?.id}
            status={chat.status}
            tabIndex={index}
            key={chat.contact.id}
            data-testid={chat.contact.id}
            onClick={() => {
              setChatAsRead(chat);
              patchGroupOfMessagesAsRead(chat.status, chat.contact.vdId);
              onSelect(chat.contact);
            }}
            direction="column"
          >
            <Flex direction="row" align="center">
              <ActivityCycleTag currentCycle={chat.contact.currentCycle}>
                {formatCurrentCycle(chat.contact.currentCycle)}
              </ActivityCycleTag>
              <ContactName
                size="bodyLargeStandardRegular"
                unread={chat.status === 'unread'}
                selected={chat.contact.id === contact?.id}
              >
                {chat.contact.name.toLowerCase()}{' '}
                <Text size="bodySmallStandardRegular" css={{ fontVariantNumeric: 'tabular-nums' }}>
                  ({chat.contact.vdId})
                </Text>
              </ContactName>
              <ContactLastMessageDateTime>
                <MessageDateTime createdAt={chat.message.createdAt} />
              </ContactLastMessageDateTime>
            </Flex>
            <Flex
              css={{
                marginTop: '$2',
                marginLeft: '33px',
                color: '$nonInteractiveAuxiliar',
                fontSize: '$auxiliar',
              }}
              align="center"
            >
              <ContactLastMessageExcerptSwitcher message={chat.message} />
            </Flex>
          </ContactItem>
        ))}
      </InfiniteScroll>
    </ContactListWrapper>
  );
};
