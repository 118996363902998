import { ChangeEvent, useState } from 'react';
import { PlayCircleOutlineIcon } from '@grupoboticario/flora-react-icons';

import { SendMessageData } from '@/shared/services/api/instant-messenger/service';
import { uploadMedia } from '@/shared/services/api/instant-messenger/service/uploadMedia';
import { events } from '@/shared/services/events';
import { createFileValidationSchema, validateFile } from '../validations';
import MediaUploaderButton from './MediaUploaderButton';

type VideoUploaderButtonProps = {
  onSendMessage: (payload: SendMessageData) => Promise<void>;
  setIsUploadingMedia: (isUploading: boolean) => void;
};

const VIDEO_MAX_FILE_SIZE = 16;
const ALLOWED_VIDEO_MIME_TYPE = ['video/mp4', 'video/3gp'];

export function VideoUploaderButton({ onSendMessage, setIsUploadingMedia }: VideoUploaderButtonProps) {
  const [renderCount, setRenderCount] = useState(0);

  async function uploadVideo(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target?.files?.length) {
      return;
    }

    const file = e.target.files[0];

    const validationResult = validateFile(
      file,
      createFileValidationSchema({
        allowedMimeTypeList: ALLOWED_VIDEO_MIME_TYPE,
        maxFileSizeInMB: VIDEO_MAX_FILE_SIZE,
      }),
    );
    if (!validationResult.isValid) {
      events.error({ description: validationResult.error });
      return;
    }

    try {
      setIsUploadingMedia(true);
      const { id } = await uploadMedia(file);
      await onSendMessage({
        provider: 'whatsapp',
        type: 'video',
        video: {
          id,
        },
      });
      events.emit('NOTIFICATION', {
        description: `Vídeo ${file.name} enviado com sucesso`,
        kind: 'success',
      });
    } catch (error) {
      return events.error({ description: 'Erro ao enviar mensagem do tipo vídeo, tente novamente.' });
    } finally {
      setIsUploadingMedia(false);
      setRenderCount(renderCount => renderCount + 1);
    }
  }

  return (
    <>
      <MediaUploaderButton
        IconComponent={<PlayCircleOutlineIcon />}
        htmlFor="video-input-uploader"
        ariaLabel="Clique para selecionar um arquivo de vídeo para enviar"
      />

      <input
        key={renderCount}
        hidden
        type="file"
        id="video-input-uploader"
        accept={ALLOWED_VIDEO_MIME_TYPE.join(',')}
        onChange={uploadVideo}
      />
    </>
  );
}
