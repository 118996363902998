import { globalCss } from '@grupoboticario/flora-react';

export const globalStyles = globalCss({
  'html, body': {
    minHeight: '100dvh',
  },
  '[id*="chatbot-ts"]': {
    position: 'relative',
    fontFamily: '$secondary',
  },
});
